import { useState, useEffect } from "react";
import { format } from "date-fns";
import { id } from 'date-fns/locale';
import { TiTime } from "react-icons/ti";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import Marquee from "react-fast-marquee";
import ScrollButton from '../Addition_Content/ScrollButton.jsx';
import SocialButton from '../Addition_Content/SocialButton.jsx';
import Bgfire from '../Assets/img/bgfire.webp';
import Jackpot from '../Assets/img/jackpot.gif';
import Fire from '../Assets/img/fire2.gif';


export default function Content({ currentItems }) {
  const [showModal, setShowModal] = useState(false);
  const [ModalParam, setModalParam] = useState(null);
  const [loading, setLoading] = useState(true);
  const loadingDuration = 1000;

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, loadingDuration); // Automatically stop loading after 3 seconds

    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  function fk(fkParam) {
    setShowModal(true);
    setModalParam({
      attributes: fkParam.attributes,
    });
  }

  function Modal(data) {
    return (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none shadow dark:bg-black dark:bg-opacity-60">
          <div className="relative my-6 mx-auto w-auto max-w-4xl">
            <div className="border-0 rounded-lg shadow-lg flex flex-col w-full bg-gray-100 bg-opacity-90 outline-none focus:outline-none">
              <div className="flex items-center justify-start p-5 border-b border-solid border-blueGray-200 rounded-t">
                <h3 className="text-2xl font-digital font-semibold">{data.attributes.title}</h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                >
                  <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">x</span>
                </button>
              </div>
              <div className="relative p-6 flex-auto max-h-96 overflow-y-auto">
                <div className="relative mb-4 object-cover">
                  <img
                    className="h-auto w-96 rounded-2xl mx-auto"
                    src={`https://datamenang.com${data.attributes.image.data.attributes.url}`}
                    alt={data.attributes.title}
                  />
                </div>
                <div className="relative flex items-center mb-1">
                  <div className="mr-1"><TiTime /></div>
                  <div><p className="text-xs">{format(new Date(data.attributes.createdAt), 'EEEE, d MMMM yyyy', { locale: id })}</p></div>
                </div>
                <p className="relative my-4 block text-black text-lg leading-relaxed text-justify whitespace-pre-line">
                  {data.attributes.description}
                </p>
              </div>
              <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <button
                  className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    );
  }

  function CardExample(param) {
    return (
      <article
        className="bg-gradient-to-t from-red-950 to-red-700 h-96 p-6 mb-6 shadow-2xl transition duration-300 group transform rounded-2xl cursor-pointer border-black"
        onClick={() => fk(param)}
      >
        <a
          target="_self"
          className="absolute opacity-0 top-0 right-0 left-0 bottom-0"
        ></a>
        <div className="relative mb-4 object-cover">
          <img
            className="h-40 rounded-2xl w-full transition-transform duration-300 transform group-hover:scale-105"
            src={`https://datamenang.com${param.attributes.image.data.attributes.url}`}
            alt={param.attributes.title}
          />
        </div>

        <h3 className="leading-8">
          <p className="text-base relative font-bold text-left line-clamp-3 text-gray-300">
            {param.attributes.title}
          </p>
          <div className="flex items-center mb-1">
            <div className="mr-1"><TiTime /></div>
            <div><p className="text-xs text-gray-400">{format(new Date(param.attributes.createdAt), 'EEEE, d MMMM yyyy', { locale: id })}</p></div>
          </div>
          <p className="text-md relative text-left line-clamp-3 leading-6 text-gray-300">
            {param.attributes.description}
          </p>
        </h3>
      </article>
    );
  }

  return (
    <div className="max-w-full flex justify-center py-8 min-h-screen bg-cover bg-center bg-no-repeat bg-fixed"
      style={{ backgroundImage: `url(${Bgfire})` }}>

      <div>
        <img className="fixed left-3 h-96 w-96 xs:hidden md:hidden 2xl:block" src={Fire} alt="fire" />
      </div>

      <div className="text-center font-digital w-full max-w-sm md:max-w-2xl xl:max-w-full md:w-4/5 2xl:w-3/5">
        <h1 className="flex justify-center text-center font-bold my-4 text-4xl text-gray-300">
          <RiMoneyDollarCircleLine className="mr-2 ml-4" />
          Bukti Jackpot Lunas {process.env.REACT_APP_NAME} :
        </h1>
        <Marquee>
          <h2 className="text-gray-300 text-md mt-4">
            SELAMAT DATANG DI {process.env.REACT_APP_NAME} BANDAR TOGEL, LIVE CASINO & SLOT TERBAIK DAN TERPERCAYA DI INDONESIA DENGAN BONUS - BONUS TANPA BATAS SETIAP HARI.</h2>
        </Marquee>
        <div className="border-dotted border-b-4 border-red-600 mb-4"></div>

        {loading ? (
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 text-center py-5 px-5 mx-auto bg-[#261d1b] rounded-2xl">
            <div className="flex justify-center items-center space-x-2 animate-pulse">
              <div className="w-10 h-10 bg-red-600 rounded-full"></div>
              <div className="w-10 h-10 bg-red-600 rounded-full"></div>
              <div className="w-10 h-10 bg-red-600 rounded-full"></div>
              <span className="text-2xl text-gray-600">LOADING.......</span>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 text-center py-5 px-5 mx-auto bg-[#261d1b] rounded-2xl">
            {currentItems.map((items) => (
              <CardExample key={items.id} attributes={items.attributes} />
            ))}
          </div>
        )}
      </div>
      <div>
        <img className="fixed bottom-40 right-3 h-52 w-52 xs:hidden md:hidden 2xl:block" src={Jackpot} alt="jackpot" />
      </div>
      <ScrollButton></ScrollButton>
      <SocialButton></SocialButton>

      {showModal && (
        <Modal
          attributes={ModalParam.attributes}
        />
      )}
    </div>
  );
}

