//icon dan logo
import wa from "../Assets/img/icon_whatsapp.png";
import fb from "../Assets/img/icon_facebook.png";
import livechat from "../Assets/img/icon_livechat.png";
import tele from "../Assets/img/icon_telegram.png";
import home from "../Assets/svg/home.svg";
import chat from "../Assets/svg/chat.svg";
import discount from "../Assets/svg/discount.svg";
import people from "../Assets/svg/people.svg";

export default function Footer() {
  const iconPath = process.env.REACT_APP_ICON;
  return (
    <footer className="p-4 bg-gradient-to-r from-black via-[#420000] to-black shadow md:px-6 md:pt-0 md:pb-8 bottom-0">
      <div className="sm:flex sm:items-center sm:justify-center xs:hidden">
        <div className="flex">
          <a href={process.env.REACT_APP_WA} target="_blank" rel="nofollow noreferrer" className="rounded-md px-3 py-3 text-sm font-medium bg-none hover:bg-gray-500" >
            <img className="h-8 w-auto" src={wa} alt="whatssup" />
          </a>
          <a href={process.env.REACT_APP_FACEBOOK} target="_blank" rel="nofollow noreferrer" className="rounded-md px-3 py-3 text-sm font-medium bg-none hover:bg-gray-500">
            <img className="h-8 w-auto" src={fb} alt="Facebook" />
          </a>
          <a href={process.env.REACT_APP_TELEGRAM} target="_blank" rel="nofollow noreferrer" className="rounded-md px-3 py-3 text-sm font-medium bg-none hover:bg-gray-500">
            <img className="h-8 w-auto" src={tele} alt="Telegram" />
          </a>
          <a href={process.env.REACT_APP_LIVECHAT} target="_blank" rel="nofollow noreferrer" className="rounded-md px-3 py-3 text-sm font-medium bg-none hover:bg-gray-500">
            <img className="h-8 w-auto" src={livechat} alt="Livechat" />
          </a>
        </div>
      </div>
      <hr className="mb-3 border-gray-200 sm:mx-auto dark:border-gray-700" />
      <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2025 <a href={process.env.REACT_APP_HOME} target="_blank" rel="nofollow noreferrer" className="hover:underline">{process.env.REACT_APP_NAME}</a>. All Rights Reserved.
      </span>

      {/* when in the mobile */}
      <div className="fixed sm:hidden bottom-0 left-1/2 transform -translate-x-1/2 inline-flex mx-auto justify-between bg-gradient-to-b from-black to-red-900 w-full rounded-t-3xl">
        <a aria-current="page" className="inline-flex flex-col items-center text-xs font-medium py-3 px-4 text-white flex-grow"
          href={process.env.REACT_APP_HOME_MOBILE} target="_blank" rel="nofollow noreferrer">
          <img className="h-8 w-auto mx-auto" src={home} alt="Login" />
          <span className="sr-only">Home</span>
        </a>
        <a
          className="inline-flex flex-col items-center text-xs font-medium text-white py-3 px-4 flex-grow"
          href={process.env.REACT_APP_DAFTAR_MOBILE} target="_blank" rel="nofollow noreferrer">
          <img className="h-8 w-auto mx-auto" src={people} alt="Daftar" />
          <span className="sr-only">Daftar</span>
        </a>
        <a className="inline-flex flex-col items-center text-xs font-medium text-white py-3 px-6 flex-grow">
          <div className="absolute bottom-5 p-3 rounded-full border-4 border-white bg-gradient-to-b from-black to-red-900">
            <img className="h-4 w-auto mx-auto" src={process.env.PUBLIC_URL + iconPath} alt="Your Company" />
          </div>
          <span className="sr-only">Logo</span>
        </a>
        <a className="inline-flex flex-col items-center text-xs font-medium text-white py-3 px-4 flex-grow"
          href={process.env.REACT_APP_LIVECHAT} target="_blank" rel="nofollow noreferrer">
          <img className="h-8 w-auto mx-auto" src={chat} alt="Live Chat" />
          <span className="sr-only">Live Chat</span>
        </a>
        <a className="inline-flex flex-col items-center text-xs font-medium text-white py-3 px-4 flex-grow"
          href={process.env.REACT_APP_PROMO_MOBILE} target="_blank" rel="nofollow noreferrer">
          <img className="h-8 w-auto mx-auto" src={discount} alt="Promo" />
          <span className="sr-only">Promo</span>
        </a>
      </div>
    </footer>
  );
}